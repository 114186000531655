<template>
	<div v-if="isEnabled">
		<vs-card>
			<div slot="header">
				<h5>{{ $t('Ask something') }}</h5>
			</div>
			<div>
				<div class="text-small-muted">{{ $t('Submit your question related to this event, it is only visible for the host') }}</div>
				<form v-on:submit.prevent="send()">
					<vs-textarea v-model="content" />
					<vs-button
						class="w-full"
						type="relief"
						:style="primaryColor"
						:disabled="!user || !participant"
						@click="send()">
						{{ $t('Submit') }}
					</vs-button>
				</form>
			</div>
		</vs-card>
	</div>
</template>

<script>
import { getAxiosErrorMessage } from '@/lib/helper';
import questionApi from '@/api/question';

export default {
  name: 'LiveQuestionForm',
  components: {
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    isVideoLive: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      content: '',
      isSending: false,
      isVerified: false,
    };
  },
  computed: {
    isEnabled() {
      let isEnabled = this.eventSettings && this.eventSettings.question && this.eventSettings.question.is_enabled;
      if (this.isLiveOnly) isEnabled = isEnabled && this.isVideoLive;
      return isEnabled;
    },
    isLiveOnly() {
      return this.eventSettings && this.eventSettings.question && this.eventSettings.question.is_on_live_visible;
    },
    isCaptchaEnabled() {
      return this.eventSettings && this.eventSettings.question && this.eventSettings.question.is_captcha;
    },
    primaryColor() {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.primary_color;
      if (!color) return '';
      const styles = {
        'background-color': `${color}`,
      };
      return styles;
    },
  },
  methods: {
    resetInput() {
      this.content = '';
      this.isVerified = false;
    },
    onVerify(response) {
      if (response) this.isVerified = true;
    },
    send() {
      // Check if captcha enabled
      if (this.isCaptchaEnabled && !this.isVerified) return;

      // Check if content not exist
      if (!this.content) return;
      const params = {
        event_id: this.event.id,
        content: this.content,
        is_moderated: 0,
      };
      this.isSending = false;
      const callback = (response) => {
        const message = response.message;
        this.isSending = false;
        this.resetInput();
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Question'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSending = false;
      };
      questionApi.create(params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
